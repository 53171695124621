<template>
  <app-layout classes="search">
    <h1 class="visually-hidden">
      Zoeken
    </h1>

    <div class="search__box p-2 p-md-3">
      <div class="search__box-wrap">
        <img
          class="search__box-icon"
          src="../assets/icon-search.svg"
          alt=""
        />
        <label class="visually-hidden" for="search">Vul hier je zoekopdracht in</label>
        <input
          class="form-control"
          type="search"
          name="search"
          id="search"
          placeholder="Vul hier je zoekopdracht in"
          @keyup="onSearchKeyup"
          @change="onSearchKeyup"
          @search="onSearchKeyup"
          :value="searchQuery"
        >
      </div>
    </div>

    <div v-if="searchQuery" class="p-2 p-md-3">
      <div v-if="searchLoadingState === 'loading'" class="text-center">
        <spinner />
        Een moment geduld&hellip;
      </div>
      <ul v-else-if="searchResults">
        <li
          v-for="result in searchResults.categories"
          :key="result.id"
          class="mb-2"
        >
          <search-result
            :title="result.translation.title"
            :icon="result.icon"
            :to="{ name: 'Category', params: { slug: result.translation.slug } }"
          />
        </li>
        <li
          v-for="result in searchResults.articles"
          :key="result.id"
          class="mb-2"
        >
          <search-result
            :title="result.translation.title"
            :icon="result.icon"
            :to="{ name: 'Snack', params: { slug: result.translation.slug } }"
          />
        </li>
      </ul>
    </div>

    <div class="p-2 p-md-3" v-if="!searchQuery">
      <h2 class="search__heading">
        Zoek op categorie
      </h2>

      <ul>
        <li
          v-for="category in categories"
          :key="category.id"
          class="mb-2"
        >
          <search-result
            :title="category.translation.title"
            :icon="category.icon"
            :to="{ name: 'Category', params: { slug: category.translation.slug } }"
          />
        </li>
      </ul>
    </div>
  </app-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import AppLayout from './AppLayout.vue';
import SearchResult from '../components/SearchResult.vue';
import Spinner from '../components/Spinner.vue';

export default {
  components: {
    Spinner,
    SearchResult,
    AppLayout,
  },

  methods: {
    onSearchKeyup(event) {
      const { value } = event.currentTarget;
      this.$store.commit('setSearchQuery', value);
      this.debouncedSearch();
    },

    onSearch() {
      if (this.searchQuery) {
        this.$gtm.trackEvent({
          event: 'search',
          category: 'Search',
          action: 'search',
          label: this.searchQuery,
        });

        this.$store.dispatch('search', this.searchQuery);

        return;
      }

      this.$store.commit('setSearchResults', []);
    },
  },

  created() {
    this.debouncedSearch = debounce(this.onSearch.bind(this), 200);

    this.$store.commit('setActiveCategory', null);
    this.$store.dispatch('loadCategories');
  },

  computed: {
    ...mapGetters([
      'user',
      'categories',
      'searchQuery',
      'searchResults',
      'searchLoadingState',
    ]),
  },
};
</script>

<style>
.app-layout.search {
  background-color: #fff;
}

.search__box {
  position: relative;
}

.search__box-wrap {
  position: relative;
}

.search__box input {
  padding-left: 2.875rem;
  height: 2.875em;
}

.search__box-icon {
  position: absolute;
  top: .9375rem;
  left: 1.125rem;
  width: 1.25rem;
}

.search__heading {
  font-size: 1.0625rem;
  margin-bottom: 1rem;
}
</style>
